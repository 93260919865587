import React from "react";
import styled from "styled-components";
import SquaredButtonStyled from "./SquaredButtonStyled";

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10000rem;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  transition: opacity 2s;

  .modal-main {
    position: fixed;
    background: white;
    width: 50rem;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 4rem;
  }

  &.display-block {
    display: block;
  }

  &.display-none {
    display: none;
  }
`;

export default function Modal({ handleClose, show, children }) {
  const showHideClassName = show ? "display-block" : "display-none";

  return (
    <ModalStyled className={showHideClassName} onClick={handleClose}>
      <section className="modal-main" onClick={(e) => e.stopPropagation()}>
        {children}
        <SquaredButtonStyled type="button" onClick={handleClose}>
          <h3>CLOSE</h3>
        </SquaredButtonStyled>
      </section>
    </ModalStyled>
  );
}
