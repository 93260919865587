import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";

import pdfIcon from "../images/pdf.png";
import downloadIcon from "../images/file_download.svg";

const UploadButtonStyled = styled.button`
  cursor: pointer;
  border: 2px ${colors.secondary} solid;
  padding: 1rem 1.9rem;
  margin: auto;
  background-color: white;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${colors.primary};
  font-family: Axiforma;

  .primary-icon {
    max-width: 30px;
  }

  .title {
    font-family: inherit;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    color: inherit;
  }

  .titles-container {
    display: flex;
    flex-direction: column;
  }

  .subtitle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 1rem;

    img {
      max-width: 14px;
    }
  }

  &: hover {
    background-color: ${colors.secondary};
    color: white;
  }
`;

export default function DownloadButton({ title, icon }) {
  return (
    <UploadButtonStyled>
      <img src={pdfIcon} alt="" className="primary-icon" />
      <div className="titles-container">
        <h3 className="title">{title}</h3>
        <div className="subtitle">
          <span>Ver</span>
        </div>
      </div>
    </UploadButtonStyled>
  );
}
