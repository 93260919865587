import React from "react"
import Modal from "./Modal"
import styled from "styled-components"
import SquareLink from "./SquareLink"

// Icons
import wpIcon from "../images/contact-icons/whatsapp.svg"
import mailIcon from "../images/contact-icons/mail.svg"
import igIcon from "../images/contact-icons/ig.svg"
import twIcon from "../images/contact-icons/tw.svg"

const ContactModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 5rem 5rem 3rem 5rem;
`

export default function ContactModal({ show, handleClose }) {
  return (
    <Modal handleClose={handleClose} show={show}>
      <ContactModalStyled>
        <h1>Contact Me</h1>
        <SquareLink
          title={"SEND WHATSAPP"}
          subtitle={"FASTEST WAY"}
          icon={wpIcon}
          iconSize={40}
          anchor={"https://api.whatsapp.com/send?phone=+524423932977"}
        ></SquareLink>
        {/* <SquareLink
          title={"SEND EMAIL"}
          subtitle={"FORMAL WAY"}
          icon={mailIcon}
          iconSize={40}
        ></SquareLink> */}
        <SquareLink
          title={"INSTAGRAM"}
          subtitle={"SEND PM"}
          icon={igIcon}
          iconSize={40}
          anchor={"https://www.instagram.com/moisesdiazmalagon/"}
        ></SquareLink>
        {/* <SquareLink
          title={"TWITTER"}
          subtitle={"SEND PM"}
          icon={twIcon}
          iconSize={40}
					anchor={"https://api.whatsapp.com/send?phone=4423932977"}
        ></SquareLink> */}
      </ContactModalStyled>
    </Modal>
  )
}
