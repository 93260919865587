import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import SquareLinkStyled from "./SquareLinkStyled";

const MainContainer = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 400px) {
    width: 100%;
  }

  .title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .subtitle {
    display: flex;
    gap: 1rem;
    align-items: center;

    h3 {
      font-size: 1.4rem;
      font-weight: 500;
    }

    img {
      filter: brightness(15%);
    }
  }

  @keyframes arrowAnimation {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(20px);
    }

    50% {
      opacity: 0;
    }

    55% {
      transform: translateX(-10px);
    }

    80% {
      opacity: 1;
    }

    100% {
      transform: translateX(0);
    }
  }
`;

export default function SquareLink({
  title,
  subtitle,
  link,
  anchor,
  icon,
  iconSize,
}) {
  const mainContent = (
    <SquareLinkStyled>
      <MainContainer>
        <h2 className="title">{title}</h2>
        <div className="subtitle">
          <h3>{subtitle}</h3>
        </div>
      </MainContainer>
      {icon && <img src={icon} width={40} className="icon-img"></img>}
    </SquareLinkStyled>
  );
  if (anchor) {
    return (
      <a target="__blank" href={anchor}>
        {mainContent}
      </a>
    );
  }
  return (
    <Link fade to={link}>
      {mainContent}
    </Link>
  );
}
