import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  :root {
    --purple-primary: #7461DF;
    --purple-darken: #4A3E8F;
    --white-accent: #F5F3FF;
    --dark-gray: #4E4E4E;
    --light-gray: #F0F0F2;
    --background-gray: #F9FAFE;
    --accent-pink: hsl(345, 88%, 63%);
    --neutral-light: hsl(222, 100%, 97%);
    --lavender-secondary: hsl(237, 21%, 52%);
    --dark-primary: hsl(237, 56%, 20%);
    --border-colour: #CAD6F1;
    --black: #0A0937;
  }

  html {
    font: 62.5% "Axiforma", georgia, serif, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; 
    font-weight: 600;
  }

  article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

  img {
  border-style: none;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;stu
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 0;
}


  *, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }

  body {
    ${"" /* background-color: var(--neutral-light); */}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    font-weight: 600;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-size: 2rem;
    background-color: #FCFCFC;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  p {
    color: var(--dark-gray);
    font-size: 1.6rem;
    font-weight: 600;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 0;
  }

  
  .secondary-heading {
    font-size: 2.6rem;
    line-height: 2.6rem;
    color: var(--purple-primary);
  }

  a { 
    text-decoration: none;
    font-weight: 600;

    &.white {
      color: white; 
    }
  }

  h1 { 
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-weight: 800;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    font-size: 5.2rem;
    color: var(--black);
  }

  h2 {
    font-size: 2.6rem;
    color: var(--black);
    font-weight: 800;
  }

  h3 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 0;
    color: inherit;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 1.38316rem;
    line-height: 1.1;
    font-weight: 700;
    font-size: 1.6rem;
    color: var(--black);
  }

  h4 {
    margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  line-height: 1.1;
  }


  h5 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.85028rem;
  line-height: 1.1;
}
h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem;
  line-height: 1.1;
}


@media only screen and (max-width: 480px) {
  html {
    font-size: 50%;
  }

}
`;

export default GlobalStyle;
