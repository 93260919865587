import React from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import colors from "../styles/colors";
import StandardLayout from "./StandardLayout";

import square from "../images/graphs/square.svg";

const ContentSectionStyled = styled.div`
  position: relative;

  h3 {
    font-weight: 800;
    margin-bottom: 1rem;
    color: ${colors.darkGray};
  }

  h2 {
    font-weight: 800;
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
  }
`;

export default function EmptyContent({
  firstTitle,
  secondTitle,
  children,
  ...props
}) {
  return (
    <StandardLayout>
      <ContentSectionStyled {...props}>
        <Fade left>
          <h3>{firstTitle}</h3>
          <h1>{secondTitle}</h1>
          {children}
        </Fade>
      </ContentSectionStyled>
    </StandardLayout>
  );
}
