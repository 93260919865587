import React from "react";
import styled from "styled-components";

import logo from "../images/logo.png";
import colors from "../styles/colors";
import GoUp from "./GoUp";

// import fb from "../images/fb.svg";
import ig from "../images/contact-icons/ig.svg";
import mail from "../images/contact-icons/mail.svg";
import tel from "../images/contact-icons/tel.svg";
import position from "../images/contact-icons/position.svg";
import whatsapp from "../images/contact-icons/whatsapp.svg";
import DownloadButton from "./DownloadButton";
import { Link } from "gatsby";
// import tw from "../images/tw.svg";

const FooterWrapper = styled.div`
  width: 100%;
  background-color: ${colors.lightGray};
  padding: 5rem 0 10rem 0;
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 3rem 8rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    gap: 3rem;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 1.4rem;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  a {
    color: ${colors.primary};
    font-size: 1.2rem;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    font-weight: 600;

    &:hover {
      color: ${colors.secondary};
    }
  }

  h3 {
    margin-bottom: 2rem;
  }

  .external-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    ul {
      display: flex;
      gap: 2rem;
    }
  }

  .links {
    display: flex;
    gap: 4rem;

    @media (max-width: 900px) {
      flex-direction: column;
      gap: 3rem;
    }

    @media (max-width: 500px) {
      flex-direction: row;
    }
  }

  .logo {
    max-width: 300px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      gap: 10px;

      a {
        padding: 1rem 0;
      }
    }
  }

  .little-icon {
    max-width: 19px;
  }
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <FooterContainer>
        <div className="logo">
          <img src={logo} width="80%" alt="" />
          <p style={{ "margin-top": "3rem" }}>
            Copyright © 2022. Grupo Proizd S.A. de CV. Todos los derechos
            reservados.
          </p>
          <p>Grupo Proizd</p>
          <p>
            <Link to="/politica-integridad">Política de integridad.</Link>
          </p>
        </div>

        <div class="links">
          <div>
            <h3>Contacto</h3>
            <ul>
              <li>
                <img className="little-icon" src={ig} alt="" />
                <a target="_blank" href="https://instagram.com/grupoproizd">
                  @grupoproizd
                </a>
              </li>
              <li>
                <img className="little-icon" src={mail} alt="" />
                <a target="_blank" href="mailto:ventas@proizd.com.mx">
                  ventas@proizd.com.mx
                </a>
              </li>
              <li>
                <img className="little-icon" src={tel} alt="" />
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=+522215823597"
                >
                  2216625523
                </a>
              </li>
              <li>
                <img className="little-icon" src={whatsapp} alt="" />
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=+522215823597"
                >
                  2215823597
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3>Ubicación</h3>
            <ul>
              <li>
                <img className="little-icon" src={position} alt="" />
                <a href="">
                  Juan Soto 18 Int. 1A, Xalapa Enriquez Centro, CP. 91000
                </a>
              </li>
              <li>
                <img className="little-icon" src={position} alt="" />
                <a href="">
                  Av. 23 Poniente 4108, Belisario Dominguez, C.P. 72180
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="external-links">
          <GoUp></GoUp>
          {/* <ul>
            <li>
              <img src={fb} alt="" />
            </li>
            <li>
              <img src={ig} alt="" />
            </li>
            <li>
              <img src={tw} alt="" />
            </li>
          </ul> */}
        </div>
      </FooterContainer>
    </FooterWrapper>
  );
}
