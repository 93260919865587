import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import colors from "../styles/colors";

const PrimaryButtonStyled = styled.button`
  background-color: ${colors.secondary};
  border: none;
  outline: none;
  padding: 1.2rem 4rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(props) =>
    props.white ? "var(--purple-primary)" : "var(--white-accent)"};
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;

  &:hover {
    filter: brightness(85%);
  }
`;

export default function PrimaryButton({ text, white, onClick, to, anchor }) {
  if (anchor) {
    return (
      <a target="_blank" href={anchor}>
        <PrimaryButtonStyled>{text}</PrimaryButtonStyled>
      </a>
    );
  }
  if (to)
    return (
      <Link fade to={to}>
        <PrimaryButtonStyled>{text}</PrimaryButtonStyled>
      </Link>
    );

  return (
    <PrimaryButtonStyled white={white} onClick={onClick}>
      {text}
    </PrimaryButtonStyled>
  );
}
