/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";

import GlobalStyles from "../styles/GlobalStyles";
import styled from "styled-components";
import Footer from "./Footer";

const Parent = styled.div`
  position: relative;
  width: 100%;
`;

const Layout = ({ children, dark }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <Parent>
      <Header siteTitle={`Title`} dark={dark} />
      <GlobalStyles />
      <main>{children}</main>
      <footer>
        <Footer></Footer>
      </footer>
    </Parent>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
