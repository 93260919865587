import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as GatsbyLink } from "gatsby";

import PrimaryButton from "./PrimaryButton";
import ContactModal from "./ContactModal";
import { context } from "../context/CustomProvider";

import logo from "../images/logo-v.png";
import colors from "../styles/colors";
import AnchorStyled from "./AnchorStyled";

const LinkStyled = styled(Link)`
  font-size: 1.6rem;
  color: ${colors.gray};
  border: none;
  text-decoration: none;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;

  &.white {
    color: white;
  }
  &:hover {
    color: ${colors.primary};
  }

  &.active {
    color: ${colors.primary};
  }
`;

const GatsbyLinkStyled = styled(GatsbyLink)`
  font-size: 1.6rem;
  color: ${colors.gray};
  border: none;
  text-decoration: none;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;

  &.white {
    color: white;
  }
  &:hover {
    color: ${colors.primary};
  }

  &.active {
    color: ${colors.primary};
  }
`;

const HeaderMainContainer = styled.header`
  /* margin-top: 4rem;
  width: 100%;
  position: relative;
  top: 0; */

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

  .call-to-action {
    justify-self: center;

    a {
      color: ${colors.primary};
      font-weight: 800;
      cursor: pointer;
    }

    @media (max-width: 1000px) {
      display: none;e
    }
  }

  @media (max-width: 1200px) {
    padding: 1rem 3rem;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
  .bar-content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    display: grid;
    padding: 1rem 0;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);

    nav ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }

    .logo {
      img {
        width: 100px;
      }
    }

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);

      .logo {
        text-align: center;

        img {
          width: 100px;
        }
      }
    }
  }
`;

const Header = ({ siteTitle, dark }) => {
  const { contactModal, setContactModal } = useContext(context);
  const [active, setActive] = useState("#home");

  const setNew = (id) => {
    setActive(id);
    scrollTo(id);
  };

  return (
    <>
      <HeaderMainContainer dark={dark}>
        <div className="bar-content">
          <div className="logo">
            <GatsbyLink to="/">
              <img src={logo} alt="" />
            </GatsbyLink>
          </div>
          <nav>
            <ul>
              <li>
                <GatsbyLinkStyled
                  activeClass="active"
                  to="/"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Inicio
                </GatsbyLinkStyled>
              </li>
              <li>
                <LinkStyled
                  activeClass="active"
                  to="us"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Nosotros
                </LinkStyled>
              </li>
              <li>
                <LinkStyled
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Servicios
                </LinkStyled>
              </li>
              <li>
                <LinkStyled
                  activeClass="active"
                  to="portfolio"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Portafolio
                </LinkStyled>
              </li>
            </ul>
          </nav>
          <div class="call-to-action">
            <AnchorStyled
              target="_blank"
              href="https://api.whatsapp.com/send?phone=+522215823597"
            >
              Contacto
            </AnchorStyled>
          </div>
        </div>
      </HeaderMainContainer>
      <ContactModal
        show={contactModal}
        handleClose={() => setContactModal(false)}
      ></ContactModal>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
