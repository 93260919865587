import styled from "styled-components"

export default styled.button`
  display: inline-block;
  border: 2px solid black;
  padding: 1.4rem 3rem 0.8rem 3rem;
  background: none;
  width: 80%;
  margin: 2rem 5rem;
  cursor: pointer;

  .icon-img {
    fill: red !important;
  }

  &:hover {
    .arrow-img {
      animation: arrowAnimation 0.5s ease-out;
    }

    border: 2px solid var(--purple-primary);
  }
`
