import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  max-width: 1500px;
  margin: auto auto;
  z-index: -100;
`;

const Standard = styled.div`
  padding: 8rem 0;
  max-width: 1200px;
  margin: auto auto;
  position: relative;

  @media (max-width: 1200px) {
    padding: 5rem 4rem;
  }
`;

export default function StandardLayout({ children, backgroundColor }) {
  return (
    <MainContainer backgroundColor={backgroundColor}>
      <Standard>{children}</Standard>
    </MainContainer>
  );
}
