import React, { createContext, useState } from "react";

export const context = createContext({
  contactModal: null,
  setContactModal: null,
});

export default function CustomProvider({ children }) {
  const [contactModal, setContactModal] = useState(false);

  return (
    <context.Provider
      value={{
        setContactModal,
        contactModal,
      }}
    >
      {children}
    </context.Provider>
  );
}
