export default {
  primary: "#303030",
  secondary: "#C6344C",
  gray: "#AAAAAA",
  darkGray: "#A7A7A7",
  lightGray: "#F1F1F1",
  lightGray2: "#E0E0E0",
  darkRed: "#611320",
  darkBlue: "#001133",
  font: "#827F7F",
  darkFont: "#626262",
};
