import React from "react";
import styled from "styled-components";

import arrowup from "../images/arrowUp.svg";
import colors from "../styles/colors";

const ButtonStyled = styled.button`
  border: none;
  outline: none;
  background-color: ${colors.lightGray2};
  width: 5.7rem;
  height: 5.7rem;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: brightness(90%);
  }

  img {
    margin: 0;
  }
`;

export default function GoUp() {
  return (
    <ButtonStyled
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <img src={arrowup} alt="" />
    </ButtonStyled>
  );
}
