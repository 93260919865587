import styled from "styled-components";

export default styled.div`
  display: flex;
  border: 2px solid black;
  padding: 1.4rem 3rem;
  background: none;

  .icon-img {
    fill: red !important;
  }

  &:hover {
    .arrow-img {
      animation: arrowAnimation 0.5s ease-out;
    }

    border: 2px solid var(--purple-primary);
  }
`;
