import styled from "styled-components";
import colors from "../styles/colors";

export default styled.a`
  font-size: 1.6rem;
  color: ${colors.gray};
  border: none;
  text-decoration: none;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;

  &.white {
    color: white;
  }
  &:hover {
    color: ${colors.primary};
  }

  &.active {
    color: ${colors.primary};
  }
`;
